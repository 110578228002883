import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CommonFunction } from '@/common/CommonFunction.js'
import { niceForm } from '@/common/form/NiceForm'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    chatReadYn: computed(() => getters['myPage/getReadYn']),
    userData: computed(() => getters['user/getData']),
    data: {},
    usePeriod: undefined,
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const fnMain = async () => {
    const res = await proxy.$MyPageSvc.postMyMain()
    if (res.resultCode === '0000') {
      state.data = res
    } else {
      alert(res.resultMsg)
    }
  }
  const {
    fnMyInfo,
    fnUpdateAuthType,
    fnLogout,
    fnWrite,
    fnMarketWrite
  } = CommonFunction(state)
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  const fnPage = async (type = 1) => {
    let path = ''
    const query = {}
    if (type === 1) {
      // 캐시
      path = '/my-page/adjustment'
      query.index = 0
    } else if (type === 2) {
      // 포인트
      path = '/my-page/adjustment'
      query.index = 1
    } else if (type === 3) {
      // 참여한 파티
      path = '/my-page/joined-party'
    } else if (type === 4) {
      // 생성한 파티
      path = '/my-page/created-party'
    } else if (type === 5) {
      // 위즈톡 게시판
      path = '/my-page/wizz-talk'
    } else if (type === 6) {
      // 파티알림설정
      if (Number(state.data.partyHopePeriod) > 0) {
        path = '/my-page/party-noti'
      } else {
        path = '/my-page/party-noti/write'
      }
    } else if (type === 7) {
      path = '/my-page/market'
      query.index = 1
    } else if (type === 8) {
      path = '/my-page/market'
      query.index = 0
    }
    await router.push({ path: path, query: query })
  }
  const fnCopy = code => {
    var t = document.createElement('textarea')
    document.body.appendChild(t)
    t.value = code
    t.select()
    document.execCommand('copy')
    document.body.removeChild(t)
    alert('텍스트가 복사되었습니다.')
  }

  const fnCenter = () => router.push({ name: 'cs-notice-index' })

  const init = async () => {
    await fnMain()
  }
  init()
  return {
    ...toRefs(state),
    fnMain,
    fnMyInfo,
    fnCallback,
    fnPage,
    fnCopy,
    fnLogout,
    fnCenter,
    fnWrite,
    fnMarketWrite
  }
}
