<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="my_page_home">
            <div class="inner" v-if="!$Util.isEmpty(data)">
              <div class="block full">
                <div class="left">
                  <div
                    class="prof"
                    :class="[
                      {
                        off:
                          data.userInfo.userLvNm ===
                          $ConstCode.LEVEL_TYPE['0'].name
                      },
                      {
                        vip:
                          data.userInfo.sellerLv >= 3
                      },
                      {
                        nonebg:
                          !$Util.isEmpty(userData.profile) &&
                          userData.profile.indexOf('/null') < 0
                      }
                    ]"
                  >
                    <!--'미인증: off 추가', '우수회원: vip 추가'-->
                    <div class="img_area">
                      <img
                        :src="userData.profile"
                        alt=""
                        v-if="
                          !$Util.isEmpty(userData.profile) &&
                            userData.profile.indexOf('/null') < 0
                        "
                      />
                    </div>
                  </div>
                  <div class="nickname">
                    {{ data.userInfo.nick }} 님, 환영합니다 :)
                  </div>
                  <div class="id" v-if="data.userInfo.loginType !== 'wizzney'">
                    <div
                      class="sv_tag"
                      :class="[
                        {
                          kakao:
                            data.userInfo.loginType ===
                            $ConstCode.SNS_LOGIN_TYPE.KAKAO.enName
                        },
                        {
                          naver:
                            data.userInfo.loginType ===
                            $ConstCode.SNS_LOGIN_TYPE.NAVER.enName
                        },
                        {
                          google:
                            data.userInfo.loginType ===
                            $ConstCode.SNS_LOGIN_TYPE.GOOGLE.enName
                        },
                        {
                          apple:
                            data.userInfo.loginType ===
                            $ConstCode.SNS_LOGIN_TYPE.APPLE.enName
                        }
                      ]"
                    >
                      {{ data.userInfo.loginType }}
                    </div>
                    <!--'카카오: kakao', '네이버: naver', '구글: google'-->
                  </div>
                </div>
                <div class="right">
                  <button class="btn_setting" @click="fnMyInfo"></button>
                </div>
              </div>
              <!-- <div class="block full" v-if="!$Util.isEmpty(data.userInfo.code)">
                <div class="left">
                  <div class="block_ttl">추천인 코드</div>
                </div>
                <div class="right">
                  <span
                    class="ext_bl"
                    @click="fnCopy(data.userInfo.code)"
                    id="code"
                    >{{ data.userInfo.code }}</span
                  >
                </div>
              </div> -->
              <div class="block vert" @click="fnPage(1)">
                <div class="left">
                  <div class="block_ttl">
                    보유 캐시
                    <div class="cash_icon"></div>
                  </div>
                </div>
                <div class="right">
                  <div class="my_price">
                    <span class="color">{{
                      $Util.formatNumber2(data.cash, { unit: '' })
                    }}</span>
                    캐시
                  </div>
                </div>
              </div>
              <div class="block vert" @click="fnPage(2)">
                <div class="left">
                  <div class="block_ttl">
                    보유 포인트
                    <div class="point_icon"></div>
                  </div>
                </div>
                <div class="right">
                  <div class="my_price">
                    <span class="color">{{
                      $Util.formatNumber2(data.point, { unit: '' })
                    }}</span>
                    포인트
                  </div>
                </div>
              </div>
              <div class="block full pc" @click="fnPage(3)">
                <div class="left">
                  <div
                    class="block_ttl"
                    :class="{ new: Number(data.participationPartyBadge) === 1 }"
                  >
                    참여파티
                  </div>
                  <!--'new'-->
                </div>
                <div class="right">
                  <span
                    :class="{
                      color: Number(data.participationPartyBadge) === 1
                    }"
                    >{{
                      $Util.formatNumber2(data.participationPartyCount, {
                        unit: ''
                      })
                    }}</span
                  >
                </div>
              </div>
              <div class="block full pc" @click="fnPage(4)">
                <div class="left">
                  <div
                    class="block_ttl"
                    :class="{ new: Number(data.createPartyBadge) === 1 }"
                  >
                    생성파티
                  </div>
                </div>
                <div class="right">
                  <span :class="{ color: Number(data.createPartyBadge) === 1 }">{{
                    $Util.formatNumber2(data.createPartyCount, {
                      unit: ''
                    })
                  }}</span>
                </div>
              </div>
              <div class="block full pc" @click="fnPage(8)">
                <!--210713 vert 변경-->
                <div class="left">
                  <div
                    class="block_ttl"
                    :class="{ new: data.buyMarketBadge > 0 }"
                  >
                    구매
                  </div>
                </div>
                <div class="right">
                  <span :class="{ color: data.buyMarketBadge > 0 }">{{
                    data.buyMarketCount
                  }}</span>
                </div>
              </div>
              <div class="block full pc" @click="fnPage(7)">
                <!--210713 vert 변경-->
                <div class="left">
                  <div
                    class="block_ttl"
                    :class="{ new: data.saleMarketBadge > 0 }"
                  >
                    판매
                  </div>
                  <!--'new'-->
                </div>
                <div class="right">
                  <span :class="{ color: data.saleMarketBadge > 0 }">{{
                    data.saleMarketCount
                  }}</span>
                </div>
              </div>
              <div class="block full" @click="fnPage(5)">
                <div class="left">
                  <div
                    class="block_ttl"
                    :class="{ new: Number(data.chatBadge) === 1 }"
                  >
                    위즈톡
                  </div>
                </div>
                <div class="right">
                  <span :class="{ color: Number(data.chatBadge) === 1 }">{{
                    $Util.formatNumber2(data.chatCount, {
                      unit: ''
                    })
                  }}</span>
                </div>
              </div>
              <!--              <div class="block full pc" @click="fnPage(6)">
                <div class="left">
                  <div
                    class="block_ttl"
                    :class="{ new: Number(data.partyHopeBadge) === 1 }"
                  >
                    알림 기간
                  </div>
                </div>
                <div class="right" v-if="Number(data.partyHopePeriod) > 0">
                  <span class="color">{{ data.partyHopePeriod }}</span
                  >일 사용
                </div>
                <div class="right" v-else>
                  알림을 설정해주세요
                </div>
              </div>-->
              <div class="btn_wrap">
                <!-- <button class="btn" @click="fnCenter">고객센터</button>
                <button class="btn" @click="fnLogout">
                  로그아웃
                </button> -->
                <button class="btn" @click="fnWrite">파티 생성하기</button>
                <button class="btn" @click="fnMarketWrite">
                  마켓 등록하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice7'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-home-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
